'use strict';
import './login.css';
import './spinner.css'
import '../public/fonts/fonts.css'
import '../public/bootstrap/bootstrap-combined.no-icons.min.css'
import './jquery-3.6.0.min'

const urlParam = function (name) {
  let results = new RegExp('[\?&]' + name + '=([^&]*)').exec(window.location.href);
  return results ? results[1] || 0 : null;
};

const setUpCommunicationChannel = () => {
  // setup Client Machine Code header
  let cmc = localStorage.getItem('cmc');
  let headers = {};
  if (cmc) {
    headers = {'NX-CMC': cmc};
  }
  $.ajaxSetup({headers: headers});
}

const isChromeUserAgent = () => {
  return navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
}

const generalErrorMessage = 'Unknown error. Please contact system administrator.';

window.apiHost = ''; // replaced by build script on non-dev
if (!window.chrome || !isChromeUserAgent()) {
  $('.change_browser_popup').show();
} else {
  setUpCommunicationChannel();

  let mfaIdentityToken = '';
  $('.login-form__field').keydown(function () {
    if ($('#error-message').css('opacity') === '1') {
      $('.login-form input.login-form__field').css('border-bottom-color', 'white');
      $('#error-message').animate({opacity: 0}, 200);
    }
  });

  const urlErrorText = urlParam('error');
  if(urlErrorText) {
    const urlError = decodeURIComponent(urlErrorText);
    console.log('Error from the backend:', urlError);
    const errorMessageElement = $('#error-message');
    errorMessageElement.text(urlError);
    errorMessageElement.animate({opacity: 1}, 200);
  }

  $('#login_submit').on('click', () => {
    let user = $('#login_username').val();
    let password = $('#login_password').val();

    const errorMessageElement = $('#error-message');
    errorMessageElement.text('');
    errorMessageElement.animate({opacity: 0}, 200);

    if (!user) {
      invalidateElement('#login_username');
      return;
    }

    if (!password) {
      invalidateElement('#login_password');
      return;
    }

    toggleLoginSpinner();
    $('#login_submit').prop("disabled", true);

    $.post(window.apiHost + '/api/v2/auth-tokens', {username: user, password: password})
      .then((data, textStatus, response) => {
        saveApiDomain(response);
        if (data.additionalFactorRequired) {
          // show MFA form
          $('#login-form').hide();
          $('#mfa-form').show();

          mfaIdentityToken = data.token;
          return true;
        } else {
          // no-MFA
          setUpAuthenticatedUser(data.token, response);
          return false;
        }
      }).then((needMfa) => {
        toggleLoginSpinner();
        if (needMfa) {
          return;
        }

        redirectToApplication();
      })
      .catch((err) => {
        $('#login_submit').prop("disabled", false);
        toggleLoginSpinner();
        setCmc(err);
        handleError(err);
        $('#error-message').animate({opacity: 1}, 200);
      });

  });

  $('#mfa-submit').on('click', () => {
    const mfaCode = $('#mfa-field').val();
    if (!mfaCode) {
      invalidateElement('#mfa-field');
    }

    toggleMfaSpinner();
    $('#mfa-submit').prop("disabled", true);

    $.post(window.apiHost + '/api/v2/auth-tokens/multi-factor', {
      identityToken: mfaIdentityToken,
      mfaResponse: mfaCode
    }).then((data, textStatus, response) => {
      setUpAuthenticatedUser(data, response);
      redirectToApplication();
    }).catch((err) => {
      toggleMfaSpinner();
      $('#mfa-submit').prop("disabled", false);

      setCmc(err);
      handleError(err);

      $('#mfa-error-message').animate({opacity: 1}, 200);
    });
  });
}

const handleError = (error) => {
  if (!error) {
    setErrorMessage(generalErrorMessage);
    return;
  }

  if (error.readyState === 0) {
    setErrorMessage('Could not connect to server. Please check your internet connection.');
    return;
  }

  if (!error.responseText) {
    setErrorMessage(generalErrorMessage);
    return;
  }

  invalidateFormElements();
  switch (error.status) {
    case 401:
      handle401(error.responseText);
      break;
    case 403:
      handle403(error.responseText);
      break;
    case 504:
      setErrorMessage('Could not connect to server. Please contact the system administrator.');
      break;
    default:
      setErrorMessage(generalErrorMessage);
      break;
  }
}

const handle401 = (responseText) => {
  const response = JSON.parse(responseText);
  switch (response.errorCode) {
    case 'INVALID_MFA_CODE':
      invalidateFormElements();
      setErrorMessage('Invalid security code.');
      break;
    case 'ACCESS_BLOCKED_DUE_TO_WORKING_HOURS':
      setErrorMessage('Access blocked due to working hours');
      break;
    case 'ACCOUNT_EXPIRED':
      setErrorMessage('Your account is expired');
      break;
    default:
      invalidateFormElements();
      handle401ErrorMessage(response)
      break;
  }
}

const handle401ErrorMessage = (response) => {
  switch (response.errorMessage) {
    case 'Failed to validate auth request':
      setErrorMessage('Invalid authentication credentials');
      break;
    case 'Failed to parse authorization token':
      setErrorMessage('Your session has expired. Please refresh and log in again.');
      break;
    default:
      setErrorMessage(generalErrorMessage);
      break;
  }
}

const handle403 = (responseText) => {
  const response = JSON.parse(responseText);
  invalidateFormElements();
  switch (response.errorCode) {
    case 'USER_HAS_BEEN_BLOCKED':
      setErrorMessage('Access blocked. Please contact system administrator.');
      break;
    default:
      setErrorMessage(generalErrorMessage);
      break;
  }
}

const setErrorMessage = (message) => {
  if ($('#login-form').is(':visible')) {
    $('#error-message').text(message);
  } else {
    $('#mfa-error-message').text(message);
  }
}

const invalidateFormElements = () => {
  if ($('#login-form').is(':visible')) {
    invalidateElement('.login-form input.login-form__field');
  } else {
    invalidateElement('#mfa-field');
  }
}

const setUpAuthenticatedUser = (data, response) => {
  console.log('Acquired token from API: ' + JSON.stringify(data));
  localStorage.setItem('token', JSON.stringify(data));

  setCmc(response);
}

const setCmc = (response) => {
  const cmc = response.getResponseHeader('NX-CMC');
  if (cmc) {
    localStorage.setItem('cmc', cmc);
  }
}

const saveApiDomain = (response) => {
  const apiDomain = response.getResponseHeader('NX-API-URL');
  const via = response.getResponseHeader('Via');
  /**
   * On the first HTTP request, we check if the response was routed through Cloudfront.
   * If it was routed through Cloudfront and there is an api domain exposed that we can use,
   * all the following API requests should be routed through the api domain.
   *
   * This check is needed because some environments are accessed in both ways:
   * - Via CloudFront on public networks
   * - Directly to ElasticBeanstalk (nginx) on internal bank VPN network
   */
  if (via && via.includes('CloudFront') && apiDomain && apiDomain.trim()) {
    localStorage.setItem('nx_apiOriginDomain', apiDomain);
  } else {
    localStorage.removeItem('nx_apiOriginDomain');
  }
}

const redirectToApplication = () => {
  let target = urlParam('target');
  console.log('Redirecting to: ', target);

  if(target) {
    window.location = `/${decodeURIComponent(target)}`;
  } else {
    window.location = '/';
  }
}

const toggleLoginSpinner = () => {
  $('#login_submit_spinner').toggle();
  $('#login_submit_text').toggle();
}

const toggleMfaSpinner = () => {
  $('#mfa-submit__spinner').toggle();
  $('#mfa-submit__text').toggle();
}

const invalidateElement = (selector) => {
  $(selector).css('border-bottom', '2px solid #FF633F');
}

